const { GrowthBook } = require('@growthbook/growthbook');
const v4 = require('uuid').v4;
const { jwtDecode } = require('jwt-decode');
const Cookies = require('js-cookie')

function readMeta(name, attribute) {
  const tag = document.querySelector(`meta[name=${name}]`);
  if (!tag) {
    return null;
  }
  if (attribute) {
    return tag.getAttribute(attribute);
  }
  return tag.getAttribute('content');
}

function readDomainCookie(name) {
  const d = readMeta('cookie_domain');
  if (d) {
    return Cookies.get(name, { domain: d });
  }
  return Cookies.get(name);
}

function writeDomainCookie(name, value, options = {}) {
  const d = readMeta('cookie_domain');
  let optionsObject = options;
  if (d) {
    optionsObject = Object.assign({ domain: d }, options);
  }
  if (Object.keys(optionsObject).length) {
    Cookies.set(name, value, optionsObject);
  } else {
    Cookies.set(name, value);
  }
}

function currentJwt() {
  return readDomainCookie('jwt');
}

function jwtData() {
  const token = currentJwt();
  if (!token) return {};

  try {
    return jwtDecode(token);
  } catch (e) {
    return {};
  }
}

function getUserId() {
  const { sub } = jwtData();
  if (!sub) return null;

  return sub;
}

const FamilyConciergeRole = 'com.sittercity.empire:Role.family_concierge';
const SitterRole = 'com.sittercity.empire:Role.provider';
const ParentRole = 'com.sittercity.empire:Role.seeker';
const PremiumRole = 'com.sittercity.empire:Role.premium_seeker';
const CorporateRole = 'com.sittercity.empire:Role.corporate_seeker';
const FeaturedSitterRole = 'com.sittercity.empire:Role.featured_provider';

const roleMap = {
 FamilyConciergeRole : 'family_concierge',
 SitterRole : 'sitter',
 ParentRole : 'basic',
 PremiumRole : 'premium',
 CorporateRole : 'corporate',
 FeaturedSitterRole : 'featured_sitter',
}

const accountRole = () => {
 const { roles } = jwtData();

 if (!roles) return null;

 // ensure the correct order of precedence
 const found = [
   FamilyConciergeRole,
   CorporateRole,
   PremiumRole,
   ParentRole,
   FeaturedSitterRole,
   SitterRole,
 ].find((role) => roles.includes(role));

 if (!found) return null;
 return roleMap[found];
}

let gb = null;

const generateCookie = () => {
  const COOKIE_NAME = 'gbuuid';
  const COOKIE_DAYS = 400;

  const existingId = readDomainCookie(COOKIE_NAME);
  if (existingId) return existingId;

  const newCookieId = v4();
  writeDomainCookie(COOKIE_NAME, newCookieId, { expires: COOKIE_DAYS });
  return newCookieId;
};

const getGrowthbook = () => {
  return gb;
};

const resetGrowthbook = () => {
  gb = null;
};

async function initGrowthbook() {
  if (gb) return gb; // Return the existing instance if already initialized

  const gbClientKey = readMeta('growthbook-client-key');
  const gbDevMode = readMeta('growthbook-dev-mode').toLowerCase() === 'true'

  if (!gbClientKey) {
    console.error('GrowthBook client key missing');
    return null;
  }

  const userId = getUserId();
  const gbuuid = generateCookie();
  const role = accountRole();

  const sendSegmentEvent = (experimentId, variationId) => {
    window.analytics.track("Experiment Viewed", { experimentId, variationId, gbuuid });
  }

  try {

    gb = new GrowthBook({
      clientKey: gbClientKey,
      enableDevMode: gbDevMode,
      attributes: { userId, gbuuid, role },
      trackingCallback: (experiment, result) => {
        if (window.analytics) {
          sendSegmentEvent(experiment.key, result.key);
        }
        else {
          window.addEventListener('segmentLoaded', () => {
            sendSegmentEvent(experiment.key, result.key);
          });
        }
      }
    });

    await gb.init({ streaming: true });

    return gb;

  } catch (e) {
    console.error('Error initializing GrowthBook:', e);
    return null;
  }
}

module.exports = {
  getGrowthbook,
  initGrowthbook,
  resetGrowthbook
};
